<script lang="ts" setup>
import { NETWORKS } from '@/app/utils/constants'

const appStore = useAppStore()

defineProps({
  sidebar: Boolean
})
</script>

<template>
  <BaseDropdown
    class="capitalize"
    transparent
    :delay="100"
    :placement="sidebar ? 'top' : 'bottom'"
  >
    <template #default="{ shown }">
      <div
        class="flex w-28 items-center justify-between gap-2 rounded px-3 py-2 text-base"
        :class="[sidebar ? 'bg-gray-300' : 'bg-gray-200 bg-opacity-40']"
      >
        <span class="min-h-2 min-w-2 bg-green-350 h-2 w-2 rounded-full" />
        <span class="font-semibold leading-4">{{
          appStore.network.display
        }}</span>
        <div :class="{ 'rotate-180': sidebar }">
          <BaseIcon
            name="caret-down-thick"
            class="w-2"
            :class="{
              'ease-in-out duration-300 rotate-180': shown
            }"
          />
        </div>
      </div>
    </template>

    <template #content>
      <div
        class="flex w-28 flex-col p-1 text-base font-semibold capitalize leading-4"
        :class="[sidebar ? 'bg-gray-300' : 'bg-gray-200 bg-opacity-60']"
      >
        <NuxtLink
          v-for="network in NETWORKS"
          :key="`network-item-${network.network}`"
          class="rounded p-2 hover:bg-gray-200 hover:bg-opacity-80"
          :to="network.baseUrl"
          target="_blank"
        >
          <div class="flex items-center justify-between">
            <span>{{ network.display }}</span>
            <BaseIcon
              v-if="appStore.network.display === network.display"
              name="check"
              class="text-blue-100 w-3 h-3"
            />
          </div>
        </NuxtLink>
      </div>
    </template>
  </BaseDropdown>
</template>
