<script lang="ts" setup>
import { PropType } from 'vue'
import { SelectorOptionWithIcon } from '@/types'

const props = defineProps({
  sidebar: Boolean,

  options: {
    type: Array as PropType<SelectorOptionWithIcon[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

function handleClose() {
  if (!props.sidebar) {
    return
  }

  emit('close')
}
</script>

<template>
  <div v-for="option in options" :key="option.display" @click="handleClose">
    <NuxtLink
      :to="option.value"
      :target="option.display === $t('common.analytics') ? '_blank' : '_self'"
    >
      <div class="flex gap-2 items-center p-2 hover:text-gray-700 text-black">
        <BaseIcon :name="option.icon" class="w-3 h-3" />
        <span class="font-bold">
          {{ option.display }}
        </span>
      </div>
    </NuxtLink>
  </div>
</template>
