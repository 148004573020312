<script lang="ts" setup>
import { Status, StatusType } from '@injectivelabs/utils'
import { GlobalEvents } from '@/types'

const status = reactive(new Status(StatusType.Idle))

onMounted(() => {
  useEventBus<StatusType>(GlobalEvents.Status).on((statusType: any) => {
    if (statusType === StatusType.Loading) {
      status.setLoading()
    }

    if (statusType === StatusType.Idle) {
      status.setIdle()
    }
  })
})
</script>

<template>
  <main class="mx-auto flex min-h-screen max-h-screen flex-col bg-gray-300">
    <LayoutTheHeader class="container z-50 mx-auto" />
    <div
      :style="{ backgroundImage: `url('/svg/bg.svg')` }"
      class="absolute h-[700px] w-full bg-cover bg-right-top blur-3xl filter"
    />

    <div class="container relative z-40 mx-auto py-10 h-full-flex">
      <AppHocLoading v-bind="{ status }">
        <main class="h-full-flex">
          <NuxtPage
            class="h-full-flex"
            :transition="{
              name: 'fade',
              mode: 'out-in'
            }"
          />
        </main>
      </AppHocLoading>
    </div>

    <LayoutTheFooter class="container z-10 mx-auto" />

    <BaseNotifications
      class="z-1110 pointer-events-none fixed inset-0 flex flex-col items-end justify-end gap-2 p-6"
    >
      <template #notification="{ notification }">
        <BaseNotification
          :notification="notification"
          class="pointer-events-auto bg-gray-800"
        >
          <template #close="{ close }">
            <BaseIcon
              name="close-bold"
              class="min-w-4 hover:text-primary-500 h-4 w-4 text-white"
              @click="close"
            />
          </template>
        </BaseNotification>
      </template>
    </BaseNotifications>
  </main>
</template>
