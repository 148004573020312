<template>
  <svg
    width="130"
    height="40"
    viewBox="0 0 130 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.3762 26.9471V15.7069C36.3762 14.4834 37.368 13.4916 38.5915 13.4916V26.9471H36.3762Z"
      fill="currentColor"
    />
    <path
      d="M41.8026 26.9471V13.4916H44.1334L49.4693 23.2565V13.4916C50.6395 13.4916 51.5882 14.4403 51.5882 15.6106V26.9471H49.2574L43.9215 17.1823V26.9471H41.8026Z"
      fill="currentColor"
    />
    <path
      d="M58.5692 27.2739C57.1951 27.2739 56.1228 26.8446 55.3523 25.986C54.5818 25.1274 54.1965 23.9869 54.1965 22.5645H56.4118C56.4118 23.3974 56.5916 24.0638 56.9511 24.5636C57.3235 25.0505 57.8629 25.294 58.5692 25.294C59.2755 25.294 59.8149 25.0505 60.1873 24.5636C60.5726 24.0638 60.7652 23.4038 60.7652 22.5837V15.7069C60.7652 14.4834 61.757 13.4916 62.9804 13.4916V22.5837C62.9804 24.0061 62.5887 25.1466 61.8054 26.0052C61.0349 26.851 59.9561 27.2739 58.5692 27.2739Z"
      fill="currentColor"
    />
    <path
      d="M65.989 13.4916H73.7712C73.7712 14.5851 72.8848 15.4715 71.7914 15.4715H68.2043V19.2775H73.7712V21.2574H68.2043V24.9672H73.7712V26.9471H65.989V19.2775V15.4715V13.4916Z"
      fill="currentColor"
    />
    <path
      d="M122.218 13.4916H130C130 14.5851 129.114 15.4715 128.02 15.4715H124.433V19.2775H130V21.2574H124.433V24.9672H130V26.9471H122.218V19.2775V15.4715V13.4916Z"
      fill="currentColor"
    />
    <path
      d="M82.828 27.2735C80.9063 27.2735 79.385 26.62 78.264 25.3128C77.143 24.0057 76.5825 22.3078 76.5825 20.219C76.5825 18.1302 77.143 16.4322 78.264 15.1251C79.385 13.818 80.9063 13.1645 82.828 13.1645C84.4408 13.1645 85.7677 13.6258 86.8086 14.5485C87.8495 15.4583 88.4615 16.6437 88.6445 18.1046C87.3845 18.1046 86.3981 17.1079 85.5287 16.1959C85.4403 16.1031 85.3465 16.0153 85.2473 15.9325C84.6296 15.4071 83.8289 15.1444 82.8452 15.1444C81.5297 15.1444 80.5002 15.6057 79.7567 16.5284C79.0247 17.4382 78.6586 18.6684 78.6586 20.219C78.6586 21.7696 79.0247 23.0062 79.7567 23.9289C80.5002 24.8387 81.5297 25.2936 82.8452 25.2936C83.8289 25.2936 84.6296 25.0309 85.2473 24.5055C85.3465 24.4227 85.4403 24.3349 85.5287 24.2421C86.3981 23.3301 87.3845 22.3334 88.6445 22.3334C88.4615 23.7943 87.8495 24.9861 86.8086 25.9087C85.7677 26.8186 84.4408 27.2735 82.828 27.2735Z"
      fill="currentColor"
    />
    <path
      d="M94.3644 26.9471V15.5676H90.454C90.454 14.4211 91.3834 13.4916 92.53 13.4916H100.509V15.5676H96.5796V26.9471H94.3644Z"
      fill="currentColor"
    />
    <path
      d="M103.321 26.9471V15.7068C103.321 14.4834 104.312 13.4916 105.536 13.4916V26.9471H103.321Z"
      fill="currentColor"
    />
    <path
      d="M112.815 26.9471L108.346 13.4916C109.779 13.4916 111.045 14.4224 111.473 15.7897L113.875 23.4679L116.277 15.7897C116.704 14.4224 117.971 13.4916 119.403 13.4916L114.934 26.9471H112.815Z"
      fill="currentColor"
    />
    <path
      d="M3.46477 11.0031C3.63175 10.7884 3.80873 10.5819 3.98571 10.3753C3.99366 10.3651 4.01161 10.363 4.01956 10.3528C4.03546 10.3324 4.06137 10.32 4.07727 10.2996L4.09317 10.2792C4.21654 10.1625 4.34786 10.0357 4.49917 9.92509C5.03469 9.50944 5.59045 9.19366 6.17646 8.98588C8.05604 8.3112 10.1483 8.72699 11.7891 10.3107C14.0801 12.5064 13.8749 16.0435 12.0461 18.3945C9.73505 21.8992 5.76355 26.7889 11.2625 31.1691C12.2512 31.9567 12.9846 32.6061 16.099 33.5263C14.0622 33.9099 12.1735 33.7906 10.0713 33.2416C8.58431 32.3834 6.24647 30.5461 5.45108 28.0644C4.24891 24.3011 7.56752 18.6753 9.17139 16.5086C11.3734 13.5099 7.81034 10.2637 5.18714 13.8877C3.81591 15.7764 1.41705 21.1208 2.25069 25.0851C2.73811 27.3332 3.38782 28.9718 5.96385 31.2228C5.48613 30.9347 5.02227 30.6077 4.57227 30.242C-1.41295 24.5417 -0.718561 15.7278 3.46477 11.0031Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M23.8393 28.9969C23.6723 29.2116 23.4953 29.4181 23.3183 29.6247C23.3104 29.6349 23.2924 29.637 23.2845 29.6472C23.2686 29.6676 23.2427 29.68 23.2268 29.7004L23.2109 29.7208C23.0875 29.8375 22.9562 29.9643 22.8049 30.0749C22.2693 30.4906 21.7136 30.8063 21.1276 31.0141C19.248 31.6888 17.1557 31.273 15.515 29.6892C13.224 27.4936 13.4291 23.9565 15.2579 21.6055C17.569 18.1008 21.5405 13.2111 16.0416 8.83085C15.0529 8.0433 14.3194 7.39392 11.205 6.47374C13.2419 6.09006 15.1305 6.2094 17.2327 6.75838C18.7197 7.61655 21.0576 9.45395 21.8529 11.9356C23.0551 15.6989 19.7365 21.3247 18.1326 23.4914C15.9306 26.4901 19.4937 29.7363 22.1169 26.1122C23.4881 24.2236 25.887 18.8792 25.0533 14.9149C24.5659 12.6668 23.9162 11.0282 21.3402 8.7772C21.8179 9.06535 22.2818 9.39229 22.7318 9.75802C28.717 15.4583 28.0226 24.2722 23.8393 28.9969Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="0.203736"
        y1="20"
        x2="27.1003"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0.203736"
        y1="20"
        x2="27.1003"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
    </defs>
  </svg>
</template>
