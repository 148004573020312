<script lang="ts" setup>
import { getHubUrl } from '@/app/utils/helper'

const hubUrl = getHubUrl()
</script>

<template>
  <div
    class="flex flex-col-reverse items-center justify-between py-5 lg:flex-row"
  >
    <div
      class="mt-4 flex w-full items-center justify-center lg:mt-0 lg:justify-start"
    >
      <span class="text-center text-xs lg:text-left">
        {{ $t('FooterCopyRightMsg', { year: new Date().getFullYear() }) }}
      </span>
    </div>

    <nav
      class="xs:flex-row xs:gap-6 flex w-full flex-col items-center justify-center gap-2 lg:justify-end"
    >
      <div class="flex items-center justify-center gap-6 font-bold">
        <NuxtLink
          :to="hubUrl"
          target="_blank"
          class="flex items-center hover:text-blue-100"
        >
          {{ $t('navigation.hub') }}
          <BaseIcon name="external-link" class="ml-1 h-3 w-3" />
        </NuxtLink>

        <NuxtLink
          :to="`${hubUrl}staking`"
          target="_blank"
          class="flex items-center hover:text-blue-100"
        >
          {{ $t('navigation.validators') }}
          <BaseIcon name="external-link" class="ml-1 h-3 w-3" />
        </NuxtLink>
      </div>
      <div class="xs:mt-0 mt-2 flex items-center gap-4">
        <NuxtLink
          to="https://discord.gg/injective"
          target="_blank"
          class="cursor-pointer text-gray-800 hover:text-blue-100"
        >
          <BaseIcon name="discord" class="h-6 w-6" />
        </NuxtLink>

        <NuxtLink
          to="https://t.me/joininjective"
          target="_blank"
          class="cursor-pointer text-gray-800 hover:text-blue-100"
        >
          <BaseIcon name="telegram" class="h-6 w-6" />
        </NuxtLink>

        <NuxtLink
          to="https://twitter.com/Injective_"
          target="_blank"
          class="cursor-pointer text-gray-800 hover:text-blue-100"
        >
          <BaseIcon name="twitter" class="h-6 w-6" />
        </NuxtLink>
      </div>
    </nav>
  </div>
</template>
