<script lang="ts" setup>
const { currentRoute } = useRouter()

const displaySidebar = ref(false)

const isIndexPage = computed(() => {
  return currentRoute.value.name === 'index'
})

function showSidebar() {
  displaySidebar.value = true
}

function hideSidebar() {
  displaySidebar.value = false
}
</script>

<template>
  <div class="flex items-center justify-between py-5">
    <div class="flex w-full items-center">
      <NuxtLink :to="{ name: 'index' }">
        <SvgLogo />
      </NuxtLink>

      <div
        v-if="!isIndexPage"
        class="xs:block ml-12 mr-6 hidden w-full md:w-80 xl:w-96"
      >
        <LayoutHeaderGlobalSearchBar :placeholder="$t('searchPlaceHolder')" />
      </div>
    </div>

    <nav class="hidden items-center gap-6 xl:flex">
      <LayoutSidebarContent class="flex items-center gap-6" />
      <LayoutNetworkSelector />
    </nav>

    <div class="relative xl:hidden">
      <BaseIcon name="menu" @click="showSidebar" />

      <div
        v-if="displaySidebar"
        class="z-1000 fixed inset-0 bg-black bg-opacity-70"
      />

      <LayoutTheMobileSidebar v-if="displaySidebar" @close="hideSidebar" />
    </div>
  </div>
</template>
