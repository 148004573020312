<script lang="ts" setup>
import { PropType } from 'vue'
import { PageCategory, SelectorOptionWithIcon } from '@/types'

defineProps({
  sidebar: Boolean,

  options: {
    type: Array as PropType<SelectorOptionWithIcon[]>,
    required: true
  },

  category: {
    type: String as PropType<PageCategory>,
    required: true
  }
})

defineEmits<{
  (e: 'close'): void
}>()

const isOpen = ref(false)
</script>

<template>
  <div>
    <BaseHoverMenu v-if="!sidebar" :delay="{ show: 200, hide: 200 }">
      <template
        #default="{
          //@ts-ignore
          shown
        }"
      >
        <div class="flex items-center gap-2">
          <slot name="prefix" v-bind="{ shown }" />

          <slot name="icon" :shown="shown">
            <BaseIcon
              name="chevron-down"
              class="h-3 w-3 min-w-3 fill-current"
              :class="{
                'ease-in-out duration-300': shown,
                'rotate-180': shown,
                'rotate-0': !shown
              }"
            />
          </slot>
        </div>
      </template>

      <template #content>
        <div class="rounded p-2 w-fit">
          <LayoutSidebarNavItemContent
            v-bind="{ options }"
            @close="() => $emit('close')"
          />
        </div>
      </template>
    </BaseHoverMenu>

    <AppAccordion v-else v-model="isOpen">
      <template #title>
        <span class="text-black" :class="{ 'text-gray-700': isOpen }">
          {{ category }}
        </span>
      </template>

      <template #content>
        <LayoutSidebarNavItemContent
          v-bind="{ sidebar, options }"
          @close="() => $emit('close')"
        />
      </template>
    </AppAccordion>
  </div>
</template>
