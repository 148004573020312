<script lang="ts" setup>
import { PageCategory, SelectorOptionWithIcon } from '@/types'

const { t } = useLang()

defineProps({
  sidebar: Boolean
})

const sidebarOptions = {
  [PageCategory.Blockchain]: [
    {
      display: t('common.blocks'),
      value: '/blocks',
      icon: 'bounding-box-circles'
    },
    {
      display: t('common.transactions'),
      value: '/transactions',
      icon: 'swap'
    }
  ],
  [PageCategory.Developers]: [
    {
      display: t('common.contracts'),
      value: '/contracts',
      icon: 'file'
    },
    {
      display: t('common.code'),
      value: '/codes',
      icon: 'arrows-line'
    }
  ],
  [PageCategory.Trading]: [
    {
      display: t('common.markets'),
      value: '/markets',
      icon: 'bar-chart-steps'
    },
    {
      display: t('common.activity'),
      value: '/trade-history/spot',
      icon: 'clock'
    },
    {
      display: t('common.analytics'),
      value: 'https://info.injective.exchange',
      icon: 'external-link'
    }
  ],
  [PageCategory.Ecosystem]: [
    {
      display: t('common.assets'),
      value: '/assets',
      icon: 'grid'
    },
    {
      display: t('common.dApps'),
      value: '/featured-dapps',
      icon: 'square-featured'
    }
  ]
} as Record<PageCategory, SelectorOptionWithIcon[]>

const emit = defineEmits<{
  (e: 'close'): void
}>()

function handleClose() {
  emit('close')
}
</script>

<template>
  <div>
    <div
      v-for="[category, options] of Object.entries(sidebarOptions)"
      :key="category"
      class="hover:text-blue-100"
    >
      <LayoutSidebarNavItem
        v-bind="{ sidebar, options, category: category as PageCategory }"
        @close="handleClose"
      >
        <template #prefix="{ shown }">
          <span
            class="text-black hover:text-gray-700 font-bold cursor-pointer"
            :class="{ 'text-gray-700': shown }"
          >
            {{ category }}
          </span>
        </template>
      </LayoutSidebarNavItem>
    </div>
  </div>
</template>
