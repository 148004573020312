<script lang="ts" setup>
defineProps({
  show: Boolean
})

const emit = defineEmits<{
  (e: 'close'): void
}>()

const sideBarWrapperRef = ref(null)
const sideBarRef = ref(null)

function hideSidebar() {
  emit('close')
}

onClickOutside(sideBarRef, () => {
  hideSidebar()
})

usePointerSwipe(sideBarWrapperRef, {
  onSwipeEnd() {
    hideSidebar()
  }
})
</script>

<template>
  <Transition name="sidebar" appear>
    <div ref="sideBarWrapperRef" class="z-1000 fixed inset-0 bg-opacity-70">
      <div ref="sideBarRef">
        <div
          class="w-18.75 z-70 sidebar-container fixed right-0 flex h-screen flex-col justify-between rounded-l-lg bg-gray-100 p-6"
        >
          <div class="w-full">
            <BaseIcon
              name="close"
              class="mb-4 ml-auto h-4 w-4"
              @click="hideSidebar"
            />
            <LayoutSidebarContent sidebar @close="hideSidebar" />
          </div>

          <div class="flex items-center justify-between">
            <LayoutNetworkSelector sidebar />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.sidebar-enter-active,
.sidebar-leave-active {
  transition: 500ms cubic-bezier(1, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  transition-property: opacity, transform;
  opacity: 1;
}

.sidebar-enter-from,
.sidebar-leave-to {
  transform: translate3d(100%, 0, 0);
  opacity: 0;
}
</style>
